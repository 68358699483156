import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

Vue.use(ElementUI);
Vue.config.productionTip = false

// axios.defaults.baseURL = "https://lzzgjt.ziyous.cn/"
axios.create({
	baseURL: '/api',
	timeout: 1000,
	headers: { 'Content-Type': 'application/json;charset=UTF-8' },
})

// 跳转后返回顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
